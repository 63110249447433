import { notification } from 'antd'

export const disclaimerEnabledEmails = ['kevin+nacha@identifee.com', 'kevin+patest@identifee.com', 'asad.ali@fore.studio']

export const classNames = (...classes) => classes.filter(Boolean).join(' ')

export const isDomainEnabled = (enabledDomains, domain) => {
  // Check for exact matches and empty array
  if (enabledDomains.includes(domain) || enabledDomains?.length === 0) {
    return true
  }
  // Check for wildcard matches
  const wildcardDomains = enabledDomains.filter(enabledDomain =>
    enabledDomain?.startsWith('*.'),
  )
  return wildcardDomains.some(wildcardDomain => {
    const wildcard = wildcardDomain.substring(2)
    return domain.endsWith(wildcard)
  })
}

export const chatFontSize = size => {
  let fontsize = 'text-sm'
  switch (size) {
    case 'small':
      fontsize = 'text-sm'
      break
    case 'medium':
      fontsize = 'text-base'
      break
    case 'large':
      fontsize = 'text-lg'
      break
    default:
      fontsize = 'text-sm'
      break
  }

  return fontsize
}
export const scrollToBottom = (animate = true) => {
  setTimeout(() => {
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: animate ? 'smooth' : 'auto',
    })
  }, 10)
}

export const scrollToBottomChat = containerId => {
  const chatBox = document.getElementById(containerId || 'chatBox')
  requestAnimationFrame(() => {
    chatBox.scrollTop = chatBox.scrollHeight
  })
}

export const showNotification = (message, description, config = {}) => {
  const defaultConfig = {
    placement: 'top', // Default placement is 'top'
    type: 'error', // Default type is 'info'
  }
  notification.open({
    message,
    description,
    ...defaultConfig,
    ...config,
  })
}

export const AlertTypes = {
  error: { type: 'error' },
  info: { type: 'info' },
  success: { type: 'success' },
  warning: { type: 'warning' },
}

export const removeQuotes = inputString => inputString?.replace(/['"]+/g, '')

export const PromptTypes = {
  Chat: 'CHAT',
  Image: 'IMAGE',
  Web: 'WEB',
  Summarize: 'SUMMARIZE',
}
// copied from IDA-API repo
export const ModelList = {
  GPT_3_5_TURBO: 'gpt-3.5-turbo',
  GPT_3_5_TURBO_16K: 'gpt-3.5-turbo-0125',
  GPT_4: 'gpt-4',
  GPT_4_32K: 'gpt-4-32k',
  GPT_4_TURBO: 'gpt-4-0125-preview',
  GPT_4_TURBO_VISION: 'gpt-4-vision-preview',
  GPT_DALLE_3: 'dall-e-3',
  CLAUDE_INSTANT: 'claude-instant-1',
  CLAUDE_INSTANT_1_2: 'claude-instant-1.2',
  CLAUDE: 'claude-2',
  CLAUDE_2_1: 'claude-2.1',
  CLAUDE_3_SONNET: 'claude-3-sonnet-20240229',
  CLAUDE_3_OPUS: 'claude-3-opus-20240229',
  SONAR_MEDIUM_ONLINE: 'sonar-medium-online',
}

export const RoleTypes = {
  Owner: 'OWNER',
  User: 'USER',
}

export const ConversationTypes = {
  WIDGET: 'WIDGET',
  USER: 'USER',
}

export const ConversationMode = {
  CHAT: 'CHAT',
  MESSAGE: 'MESSAGE',
}

export const disclaimerMessage = `Permission to use Nacha Operating Rules & Guidelines granted by the National
Automated Clearing House Association (Nacha). Copyright © 2024 Nacha. All
Rights Reserved. Nacha has not reviewed and is not responsible for the content on
or generated by this website or service. IDA users should own the most current
version of the official Nacha Operating Rules & Guidelines. To purchase the Nacha
Operating Rules & Guidelines, go to http://www.nacha.org/store  or contact your
Payments Association.`
